
import { XIcon } from "vue-feather-icons";
import ToastManager from "../../fc/ToastManager";
export default {
  name: "ToastContainer",
  components: { XIcon },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    toast: {
      default: {},
      required: true,
    },
  },
  computed: {
    toastManager() {
      return new ToastManager(this);
    },
  },
  mounted() {
    const id = this.toast.id;
    if (!this.toast.offLine) {
      // auto-close enabled for all toasters except offLine
      setTimeout(() => {
        this.close(id);
      }, 4000);
    }
  },
  methods: {
    close(id) {
      this.toastManager.remove(id);
    },
  },
};
