
import ToastManager from '~/fc/ToastManager'
import Toast from /* webpackChunkName: "Toast" */ './Toast'

export default {
  name: 'ToastContainer',
  components: {
    Toast
  },
  computed: {
    toasts () { return this.$store.state.toasts },
    toastManager () { return (new ToastManager(this)) }
  }
}
